<template>
  <div>
    <label v-if="$slots.label"
           :for="id"
           class="mb-3 h-4 block text-sm text-black font-medium first-letter:uppercase whitespace-nowrap"
    >
      <slot name="label"></slot>
      <span v-if="required" class="text-red-500">{{ ' *' }}</span>
    </label>

    <div class="relative rounded-lg flex items-center gap-2 text-sm"
    >
      <div v-if="$slots['before-input']"
           class="ms-2 text-xs text-black/50 whitespace-nowrap"
           @click="focusInput()"
      >
        <slot name="before-input"></slot>
      </div>
      <slot name="input"
            :id="id"
            :ref="setRef"
            :required="required"
            :disabled="disabled"
            :class="{
                    'border border-zinc-300 focus-within:border-green-200 rounded-lg ': !noBorder,
                    'bg-white': !disabled,
                    'bg-neutral-100': disabled,
                }"
      ></slot>

      <div v-if="$slots['after-input']"
           class="absolute right-0 top-0 bottom-0 flex items-center mr-2 text-xs text-black/50 whitespace-nowrap"
           @click="focusInput()"
      >
        <slot name="after-input"></slot>
      </div>
    </div>

    <div v-if="$slots['password-rules']"
         class="grid grid-cols-2 grid-rows-2 gap-1 mt-2 text-xs gap-x-14"
    >
      <slot name="password-rules"></slot>
    </div>


    <FormError v-if="$slots.error || error">
      <slot name="error">
        {{ error }}
      </slot>
    </FormError>
  </div>
</template>

<script setup>
import FormError from '@/Components/Client/Forms/FormError.vue';
import {ref} from 'vue';

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: () => '',
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
  required: {
    type: [Boolean, Number],
    default: () => false,
  },
  error: {
    type: String,
    default: () => null,
  },
  noBorder: {
    type: Boolean,
    default: () => false,
  },
});

const id = (Math.random() + 1).toString(36).substring(2);
const inputRef = ref();

defineExpose({
  focus: () => inputRef.value?.focus?.(),
  blur: () => inputRef.value?.blur?.(),
});

function setRef(element) {
  if (element) {
    inputRef.value = element;
  }
}

function focusInput() {
  inputRef.value?.focus?.();
}
</script>
